import React from 'react'

import './styles.css'

export default function Software() {
  return (
    <React.Fragment>

      {/* <!-- Section 9 --> */}
      <div id="software" className="cntdiv slide">

        <div className="stagepromo">

          <h1>Our Business Courses are an Advantage to Learning, Understanding Principles, and Patterns.</h1>

        </div>

        <div className="stage">

          <div className="container">

            <p>Knowing the &quot;hard&quot; skills of marketing (Promotion, Strategies, Tactics, Planning, Advertising, Headlines, Discovery…etc.) is only the fundamental part of marketing. The other part of the job of marketing is often the most challenging but the most important to stand out in a crowded marketplace with the messages your company places, no matter what media you are using.</p>
            
            <p>Marketing skills require effort along with time and study. Proficiency in critical areas as such communication with the world, can be cost effective and not wasteful. With the marketing tools in hand, and with disciplined, well-organized, marketing knowledge, a business can be highly effective in messaging. And learning how to target a market in a crowded marketplace, with a message displayed to a correct target is learnable.</p>

            <p>Businesses with limited marketing experience will learn all the secrets and prevent the downfalls with checks and balances in our business courses.</p>
            
            <p>Learn what one of the most inefficient things is that YOU can do!</p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}