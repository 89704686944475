import React from 'react'

import './styles.css'

export default function Fires() {
  return (
    <React.Fragment>

      {/* <!-- Section 11 --> */}
      <div id="fires" className="cntdiv slide light">

        <div className="stagepromo">

          <h1>ONE OF THE MOST INEFFICIENT THINGS YOU CAN DO!</h1>

          <img src="/images/chain.jpg" alt="broken link image" />

        </div>

        <div className="stage">

          <div className="container">

            <div className="two-column-w-image">

              <img src="images/flame.jpg" alt="fire image" style={{
                float: 'right',
                width: 360,
                marginLeft: 30,
              }} />
              
              <p>If putting out fires is what you spend most of your day doing, then you need to understand the difference between marketing vs. instinct. If you are running &amp; managing your business by accident or what you think is instinct (feeling), then the results of that habit will manifest itself in all areas of your business &amp; your messaging in the marketplace will suffer!</p>

              <p>The human brain is capable of understanding incredibly complex and intricate concepts. However, when a business is managed by someone&apos;s instinct it is hard to recognize the obvious and simple.</p>

              <h3>EXAMINING OURSELVES!</h3>

              <p>If you are a person that deals with an all-knowing attitude, you probably should not be in messaging or marketing. This holds true if you work with this type of personality.</p>

              <p>
                Self-test yourself to see if you need this course on messaging.
                <ol>
                  <li>Does it annoy you when your views do not dominate?</li>
                  <li>Do you use your capital in the company to manipulate, control, influence, steer things your way, or keep trying to drive a point home?</li>
                  <li>Do you write a prescription for others?</li>
                </ol>
              </p>
              
              <p>
                How about these two questions!
                <ol>
                  <li>Are you overwhelmed by doubts and difficulties? Or do you say those are things I don&apos;t need to understand? They are trivial as to what we are doing here.</li>
                  <li>Do you react to a question by being understanding, or toss out a shot in the dark?</li>
                </ol>
              </p>

              <p>People crave success at the expense of almost anything and sometimes anybody else that gets in the way. The destabilizing effect of instinct marketing thinking, along with a person&apos;s rampant ego, is the downfall of many businesses. Anyone can change! It is a big help in the curve of change, when we start understanding and learning principles.</p>

              <p>Always be on watch to recognize the opportunities that will come in the future!</p>
              
            </div>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}