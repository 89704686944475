import React from 'react'

import './styles.css'

export default function Conclusion() {
  return (
    <React.Fragment>

      {/* <!-- Section 16 --> */}
      <div id="conclusion" className="cntdiv slide light">

        <div className="stagepromo">

          <h1>Concluding Thoughts</h1>

        </div>

        <div className="stage">

          <div className="container">

            <p>To fail to accomplish and obtain this knowledge, is to admit that one has not paid the price. Like academic degrees, it is obtained by intense strivings, to exert yourself vigorously. This knowledge of marketing principles and messaging skill is obtained by desires one reaches for. One investigates carefully, studies, and with effort, time, and work, obtains this knowledge and understanding. A sure knowledge of this, opens doors to great rewards and confidence. To ignore and overlook the benefits of marketing knowledge, is to fumble in caves of thick darkness, to creep along in fog over hazardous freeways that lead to dead ends. That person is to be pitied who may still be walking in darkness at noonday, who is tripping over obstacles that can be removed, and who lives in the dim flickering candlelight of insecurity and skepticism when he needs not. Marketing principles can be known by every responsible person as surely as the knowledge that the sun shines.</p>
              
            <p>Marketing elements and essentials are controlled through principles and the pattern&apos;s followed generate confidence. Staggering in the marketplace with messaging is caused by non-marketing knowledge and understanding. You can replace doubted decisions with confident action, followed by evidence and signs of what works and what fails. Mistakes of the past can be reversed into positive development. One&apos;s energies that were wasted in the indecisive opinions of past decision making can become trusted energy output, with understanding that pays off.</p>
              
            <p>The - knowledge of principles of marketing is like the electric light, illuminating the cavern, removing the thick darkness; the wind and sun dissipating the fog to remove hazardous traveling on the freeways of the network business system; the power equipment removing boulders from the road so the obstacles of inexperience making progress or success difficult. Principles are the mansion on the hill replacing the shack in the swamplands; the harvester abandoning the sickle for fast yield; the tractor, train, automobile, and plane displacing the horse and buggy. Marketing principles are the rich nutritious kernels of corn instead of the husks in the trough. When Marketing Principles are followed and used; when principles are understood and make sense to the mind to the point of being a habit rather than a struggle, then you are not walking in darkness at noonday, but walking in a clear bright sunny day, without questions and wrinkles on your brow!</p>

            <p>With our courses we will help you stop being plagued with self-deception. Self-deception is so pervasive it touches every aspect of life. Self-deception actually influences and determines one&apos;s experience in every aspect of life. It is a central issue in marketing and messaging which prevents accuracy. Having the problem in marketing, is rampant and bias is flourishing and spreading unchecked. The thing is, we can&apos;t see it, in ourselves. Having the problem effect the solutions of marketing, with each and every different targeted market, you are messaging to. Consider what bias does in marketing. This is a cause of how we feel or show inclination or prejudice for or against someone or something. In favor of or against one thing, or one group compared with another. A marketer must understand the pattern that needs to be followed to break a bad pattern of  bias and self-deception.</p>
            
            <p>So, start today with the learning process. Unburdening oneself of brow wrinkles. Having a clear understanding, rather than relying on what most follow &quot;human logic&quot; which reduces principles to irrational thoughts, rather than researched evidence.</p>
            
            <p>Get started NOW with educating yourself, learning how to market your methods, for all impending endeavors. Thank you for visiting our site.</p>
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}