import React from 'react'

// components
import Footer from '../../components/Footer'
import Header from '../../components/Header'

//styles
import './styles.css'

export default function Contact() {
  
  return (
    <div id="contact">
      
      <Header />

      <section className="container">

        <h1>Contact American Business Consultants</h1>

        <p>Send us and email at <a href="mailto:info@americanbusinessconsultants.com">info@americanbusinessconsultants.com</a>.</p>

      </section>

      <Footer />
      
    </div>
  )
}
