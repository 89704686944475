import React from 'react'

import './styles.css'

export default function Consequences() {
  return (
    <React.Fragment>

      {/* <!-- Section 8 --> */}
      <div id="consequences" className="cntdiv slide light">

        <div className="stage">

          <div className="container">

            <h2>Eventual effects of bad marketing bring unavoidable results! When bad marketing choices because of not knowing principles are made they establish a fact of consequences. Which plainly proves the application of laws and principles of marketing were not followed.</h2>

            <br />
            <p>We have repeated this concept many times here, hope it is sinking in, and hitting home with you!</p>
            
            <p>The horse and buggy businesses years ago looked at the new upcoming automobile industry with a &quot;WAIT-AND-SEE ATTITUDE&quot;.  Look what happened to them…</p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}