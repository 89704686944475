import React from 'react'

import './styles.css'

export default function Pricing() {
  return (
    <React.Fragment>

      {/* <!-- Section 7 --> */}
      <div id="pricing" className="cntdiv slide light">

        <div className="stage">

          <div className="container">

            <h2>If You Gain A Customer Because Of Your Prices, You Will Then LOSE That Customer Because Of Your Prices! This is a weak marketing plan! PRICING TO GAIN A CUSTOMER.</h2>

            <p>Every year all company&apos;s lose accounts. Most salespeople are pretty accepting about that.  When an account is lost, most companies just go on with business. Spending sometimes a lot of money to gain new customers. BIG mistake! Learn how to keep your customer&apos;s loyalty through marketing principles.</p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}