import React from 'react'

import './styles.css'

export default function Trumpeting() {
  return (
    <React.Fragment>

      {/* <!-- Section 15 --> */}
      <div id="trumpeting" className="cntdiv slide light">

        <div className="stagepromo">

          <h1>TRUMPETING vs. ANNOYING NOISE</h1>

          <div className="header-images">

            <img src="/images/horn.png" alt="trumpeting image" />

            <img src="/images/annoying.jpg" alt="annoying image" />

          </div>

        </div>

        <div className="stage">

          <div className="container">

            <p><strong>Do the messages your company puts out in the marketplace come as trumpeting music to the ear?</strong> Or do they just add to the many annoying sounds that so many ads make nowadays? So many are like a pest, a nuisance, or pain to deal with every day, what a brain hassle! And the disturbances go on and on and on? Those are the companies that are not targeting groups with knowhow and understanding, but using what we call shotgun advertising!</p>

            <h1 className="remember">
              Remember This &nbsp;&#8681;
            </h1>

            <h3>Consumers Deal with an average of 5000 Messages Per Day in the Marketplace.</h3>

            <p>The average American consumer is confronted by more than 5,000 selling messages per day – such as mail, email, SMS/smartphone, blogs, online messaging, radio, television, magazines, newspapers, billboards, posters, point-of-purchase displays, and product packaging - each one hoping to gain customers&apos; eyes, ears and attention. The future will be more &quot;sure messaging&quot; from online analytics, recognizing each target market for messaging.</p>

            <p>However, there is another aspect that goes completely overlooked. That is, the ambient noise such as found in big city life. With the constant roar of the city &quot;noise on noise&quot;. That noise is blocked out by most people. There are constant sirens heard throughout the city. For the most part people can block these noises out. They have to or life would be even more perplexing than it is. You might say they are annoying sounds. However, they become a welcome sound, when an event causes a need. A siren can be a pleasant sound when there is a need.</p>

            <p>A consumer must block out most messaging so as not to become overwhelmed. Is your messaging being blocked out?</p>

            <p>Just understanding this concept is big time important and is very compelling for the implementation of marketing principles in your business.</p>
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}