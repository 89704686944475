import React from 'react'

// components
import Footer from '../../components/Footer'
import Header from '../../components/Header'

//styles
import './styles.css'

export default function About() {
  return (
    <div id="about">
      
      <Header />

      <section>

        <div class="container">

          <h1>
            About AmericanBusinessConsultants.com
          </h1>

          <p>
            AmericanBusinessConsultants.com founders are a &quot;Results Marketing&quot; minded team.
            Through their years of marketing they have been instrumental in changing and retrofitting a whole industry. That industry was the bulk vending industry. At the time of their beginnings there were only small red and chrome bulk vending machines. At that time these bulk vending machines were mostly limited to grocery stores. We were instrumental in the concept and creation of bringing to market in the marketplace, a unique machine that created a movement in the vending industry and helped launch many new small businesses to a great business opportunity.
          </p>

          <p>
            AmericanBusinessConsultants.com was also directly involved in the creation of a new unique industry called &quot;Balloon Stuffing&quot;, where the gift being given, was presented inside a balloon, the balloon being the package/gift wrap. Not only was a unique machine developed, but the business concepts and marketing avenues were created. This resulted in working with approximately 23 different countries, many using our machines and launching this concept of gift giving in their countries. We exported dozens, to full containers, of machines to several countries, and had foreigners from other countries fly in to meet with us to purchase and get training on how to market the concept in their perspective country as dealers.
          </p>

          <p>
            Today&apos;s vision is based on courage and unwavering spirit to support businesses using marketing techniques based on sound marketing laws and principles through our easy-to-use software and manuals. Our goal is to continue to bring the opportunity of achievement, and the potential to make dreams become a reality, through the use of inspiring, enriching and empowering, software and manuals. Each of our manuals and software offers marketing principles and laws to get the job done in a crowded marketplace.
            &quot;You can do it!&quot; - Is our attitude.
          </p>

          <p>
            True marketing brings success stories. Over the years, there have been many successful business leaders that have accomplished their dreams through the use of marketing principles. Our software and manuals can leave an unforgettable mark and open the door for businesses around the world to experience success because of their efforts in the learning, changing, and then doing process.
          </p>

          <p>
            These words embody the very spirit of AmericanBusinessConsultants.com. It&apos;s the spirit that must be learned as one faces new and daunting tasks, in a crowded marketplace, with courage. &quot;You can do it. You can do it.&quot; Embrace this empowering spirit, and pass it on throughout your company, inspiring all within your business to learn and use marketing principles.
          </p>

          <p>
            Make it the law of your company to learn the laws and follow them in whatever is to come, facing situations with courage and always committing to a marketing principled position. This will always bring the visionary aspect out in a company and will be shown in its actions. Opportunities are always in the marketplace.
          </p>

          <p>
            This is much more than creating a plan that may or may not work out, and much better than the free advice everyone around is always giving without the risk. Develop your talents in marketing principles, and use them to achieve unlimited success.
          </p>

          <p>
            We envision any company using our software and manuals, becoming as successful as they want to be. The doors are always wide, open to opportunity for those willing to pay the price by learning and using marketing principles and laws. Success is much deeper than just dollars and cents, and buildings and assets. The real success of any company is measured in the lives that have been changed and given hope, through the learning process. <a href="http://www.americanbusinessconsultants.com" target="_blank">AmericanBusinessConsultants.com</a>
          </p>
              
        </div>

      </section>

      <Footer />
      
    </div>
  )
}
