import React from 'react'

import './styles.css'

export default function Mistakes() {
  return (
    <React.Fragment>

      {/* <!-- Section 4 --> */}

      <div id="mistakes" className="cntdiv slide light">

        <div className="stage">

          <h1 style={{padding: '0 2%'}}>Don&apos;t Make Deadly Mistakes 
            With Your Hard-Earned Dollars 
            Trying To Be Found In A Crowded Marketplace
            </h1>

          <div className="container">
            <h2>A business must attract customers - Some business practices are actually a repellent to customers.</h2>

            <p style={{ marginBottom: 30 }}>
              <img src="/images/magnet.png" alt="" style={{
              }} />
              <h3 className="image-caption">This three way peg magnet shows how confusing a company can be when caught up in a pull-push force, within a business.</h3>
            </p>

            <p>If you are frustrated with lousy results from your messaging ads, but don&apos;t know what to do about it? Do not give up! Start learning marketing principles and patterns.</p>
            
            <p>A message may be a solid message, but if you are messaging in shotgun kind of messaging (putting out a message to the crowds and hoping some will respond) this means thousands must hear your message before just a few, that are interested in your product or service, will respond.</p>

            <p>It is a must that you find the ways to standout in a crowded marketplace!</p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}