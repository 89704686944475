import React from 'react'

import './styles.css'

export default function Recession() {
  return (
    <React.Fragment>

      {/* <!-- Section 2 --> */}
      <div id="recession" className="cntdiv slide">

        <div className="stagepromo">

          <h1>
            This Is The Greatest Time For Opportunity!</h1>

          <h3>
            Currently, 1.3 million Android <span style={{ fontWeight: 900, fontSize: '120%' }}>devices are activated every day</span> – that means you have that many more prospects to message your ads through each day. What an opportunity.</h3>

        </div>

        <div className="stage">

          <div className="container">

            <h1 style={{ display: 'inline-block' }}>
              Recession Made Millionaires! &nbsp;</h1>

            <h2 style={{ display: 'inline-block' }}>
              It is always opportunity time!</h2>

            <p>
              Enterprising citizens searched the marketplace for an opportunity to make it through the depression, and many made it big.  We face a time now that the world economy is unlike anything ever before in our history. The nation&apos;s debt has grown huge. Opportunities are present no matter what is going on in the marketplace. Not everyone suffered during the Great Depression. More people became millionaires during this time than in any other time in American history. Opportunities are available. An economic downturn is a good time to re-examine a business. Know-how marketing entrepreneurs will find ways to position themselves, until the economic climate improves. If you can dream it, you can do it, and create a new understanding of marketing in the marketplace for your business. Learn what you have put off in the past, principles of messaging.</p>
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}