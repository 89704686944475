import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// views
import About from './views/About'
import Contact from './views/Contact'
import Home from './views/Home'
import NotFound from './views/404'

// styles
import './assets/css/App.css'

export default function App() {
  return <BrowserRouter>
    <div id="top" className="app">
      <Switch>
        <Route path="/" component={() => <Home />} exact />
        <Route path="/about" component={() => <About />} exact />
        <Route path="/contact" component={() => <Contact />} exact />
        <Route path="*" component={() => <NotFound />} />
      </Switch>
    </div>
  </BrowserRouter>
}
