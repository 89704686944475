import React from 'react'

import './styles.css'

export default function Fundamentals() {
  return (
    <React.Fragment>

      {/* <!-- Section 10 --> */}
      <div id="fundamentals" className="cntdiv slide light">

        <div className="stagepromo">

          <img src="/images/foundation.jpg" alt="Foundation" />

          <h1 style={{ display: 'inline-block' }}>FUNDAMENTALS - FOUNDATIONAL</h1>

        </div>

        <div className="stage">

          <div className="container">

            <h3><span style={{ fontSize: '2rem' }}>S</span>uccessful marketing happens with understanding and knowledge of the marketplace.</h3>
              
            <ul>
              
              <li>Learning how to attack it is doable.</li>
                
              <li>Knowledge enables one&apos;s courage when facing the adversity in the marketplace.</li>
                
              <li>Marketing ignorance and carelessness in messaging, leads to error.</li>
                
              <li>The assumption, that under given conditions, and with determined effort, anything in the marketplace can be overcome. On the contrary, to avert the effects of no marketing knowledge, we suggest, it is experience and knowledge that creates conditions that bring customers to your door, successful messaging is the key.</li>
              
            </ul>

            <h2 className="approach">
              Experience, Learn, Change...<br />
              Is how you approach the<br />
              marketplace...
            </h2>

            <h3>We Should be Improving our Marketing Knowledge all or our life...</h3>

            <h3>We all have weaknesses in marketing areas, so we must rely at times on the knowledge of others to get the job done.  We must also be willing to spend the time and effort to develop personal marketing knowledge.</h3>

            <p>NEVER STOP LEARNING - this is a principle and the pattern is to always be engaged in having Self-Reliance as a Marketer, it is a key to being a &quot;Master Marketer&quot;.</p>

            <p>Learning takes time and effort. Though we understand this in our schooling days of our lives, we are often impatient in our marketing education and messaging development. We sometimes feel that we ought to do more educating ourselves than we do, in order to remove some of the distress we experience when we realize that we do not have a ready answer for every marketing messaging question. So just do it! Learn!</p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}