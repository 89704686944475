import React from 'react'

// components
import Consequences from './Consequences'
import Conclusion from './Conclusion'
import Courses from './Courses'
import Fires from './Fires'
import Fundamentals from './Fundamentals'
import Keystone from './Keystone'
import Leverage from './Leverage'
import Marketplace from './Marketplace'
import Mind from './Mind'
import Mistakes from './Mistakes'
import Pricing from './Pricing'
import Principles from './Principles'
import Promote from './Promote'
import Recession from './Recession'
import Software from './Software'
import Trumpeting from './Trumpeting'

import './styles.css'

export default function Marketing() {
  return (
    <React.Fragment>

      <Marketplace />

      <Recession />

      <Mind />

      <Mistakes />

      <Promote />
      
      <Principles />

      <Pricing />

      <Consequences />

      <Software />

      <Fundamentals />

      <Fires />

      <Leverage />

      <Keystone />

      <Courses />

      <Trumpeting />

      <Conclusion />

    </React.Fragment>
  )
}