import React from 'react'

//styles
import './styles.css'

export default function NotFound() {
  return (
    <div id="not_found">

      <h1>404 - page not found</h1>
      
    </div>
  )
}
