import React from 'react'

import './styles.css'

export default function Courses() {
  return (
    <React.Fragment>

      {/* <!-- Section 14 --> */}
      <div id="courses" className="cntdiv slide light">

        <div className="stagepromo">

          <h1>OUR BUSINESS COURSES</h1>

        </div>

        <div className="stage">

          <div className="container">

            <img src="/images/course.jpg" alt="course" className="courses" />
            
            <p>Are keystones for businesses – our courses provide a system for organized learning, with 24/7 access. You can learn and follow, then use and apply, the marketing knowledge gained. You will learn the laws, and principles in patterns of developing and creating messages that will &quot;standout in the crowd&quot;... a big, crowded marketplace. 
              <img src="/images/docs.jpg" alt="documents" className="docs" />
              &nbsp;Our courses offer easy to follow, step-by-step, checklists on the task at hand in developing and creating a successful targeted massage to a group in the marketplace (courses include forms and instruction sheets that guide you).</p>

            <p>Our course orchestrates the process, helps bring the resources and research together. Providing a step-by-step development of business messaging advertising marketing know-how in a timely, skillful progression of accomplishment. When it&apos;s critical, you can rely on a course that provides in-depth instruction that will help you become disciplined, well-organized, and highly effective in developing messages for the marketplace.</p>

            <div style={{ clear: 'both' }} />
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}