import React from 'react'

import './styles.css'

export default function Mind() {
  return (
    <React.Fragment>

      {/* <!-- Section 3 --> */}
      <div id="mind" className="cntdiv slide">

        <div className="stagepromo">

          <h1>
            The FUNDAMENTALS of Opportunity!</h1>

          <h3>
            Importance of the mind &amp; controlling the mind.</h3>

        </div>

        <div className="stage">

          <div className="container">

            <p>You turn into what you think about – How you think is what you develop into.  &quot;AS <strong>YOU THINK</strong>, SO <strong>YOU BECOME</strong>&quot;., and because our life is formed by our minds, what we think, is what we turn out to be.  The mind is <strong>&quot;idea&quot;</strong> matter; whatever is in one&apos;s mind determines whatever comes out in words. Next, the words are manifested in actions; actions form our habits; and habits create our character. Our thoughts processed in our mind eventually become obvious in our facial expression, our speech, our body language and our basic attitude. What we consistently nurture in our minds determines our character, life, and our destiny. By choosing to start an educational learning, personal development, business marketing knowledge base, so as to understanding the principles.</p>

            <p>So changing one&apos;s thoughts changes our character, attitude, and life. The more aware one is of this, &quot;Man is the architect of his destiny&quot;, the sooner change will begin through learning principles.</p>
            
            <p> These three men had a tendency to look at the future with pessimism. </p>	
            
            <p> Here are some examples of negative thinking.</p> 		
            
            <p> <img src="/images/duell.png" alt="" style={{	
              float: 'left',	
              marginRight: '18px',	
            }} /> <img src="/images/millikan.png" alt="" style={{	
              float: 'left',	
              marginRight: '18px',	
            }} /> <img src="/images/thomson.png" alt="" style={{	
              float: 'left',	
              marginRight: '18px',	
            }} /></p>	
            
            <p> If you are stuck in a rut. Feeling burned out – exhausted. Feeling the circumstances around you are hopeless.  All these feelings can frustrate you and discourage you. Do you feel you can't change things?</p>	
            
            <p> Pessimism is the tendency to take the most unfavorable view of situations, or to expect the worst outcome in any circumstances; the practice of looking on the gloomy and dark side of things.</p>	
            
            <p> Positiveness is optimism; the tendency or habit of seeing and expecting the good in things; looking on the bright side; anticipating favorable results will always open opportunities and change circumstances. </p>

            <br />&nbsp;
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}