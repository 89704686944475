import React from 'react'

import './styles.css'

export default function Leverage() {
  return (
    <React.Fragment>

      {/* <!-- Section 12 --> */}
      <div id="leverage" className="cntdiv slide light">

        <div className="stagepromo">

          <h1>What provides Leverage in the marketplace can cause a competitive edge!</h1>

        </div>

        <div className="stage">

          <div className="container">

            <h3>It&apos;s important to identify specific ways to strengthen your approach in the marketplace to leverage every opportunity.</h3>

            <p>Regardless of individual business circumstances, misplaced priorities will threaten, destroy and undermine the basic laws of success. If a business is to survive in an environment that seems ever more crowded, the contrast is found in laws and principles of marketing and messaging, used by businesses. There are those that dilute the marketplace and create the static confusion and atmosphere of uncertainty.  Those businesses that are rooted in marketing principles, stand out in the crowd. There can be no genuine success in the market, without principled marketing.</p>

            <p>How do you protect, preserve and strengthen a business in a world pulling so hard in opposite directions? By creating a creditable identity that is stronger than any competitor?  Again - With marketing laws and principles, that&apos;s how!</p>

            <p>The horse and buggy businesses years ago, looked at the new upcoming automobile industry with a &quot;WAIT-AND-SEE ATTITUDE&quot;. Look what happened to them!</p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}