import React from 'react'

// components
import Footer from '../../components/Footer'
import Marketing from '../../components/Marketing'

//styles
import './styles.css'

export default function Home() {
  return (
    <div id="home">

      <header className="home-header">
        <h1 className={'home-splash'}>
          American
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Business
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consultants
        </h1>
        <h2>An Idaho Marketing Company</h2>
        <p className="links">
          <a href="#marketing">Marketing</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/about">About</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/contact">Contact</a>
        </p>
      </header>

      <section id="marketing">
        <Marketing />
      </section>

      <Footer />
      
    </div>
  )
}
