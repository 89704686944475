import React from 'react'

import './styles.css'

export default function Footer() {
  return (
    <footer id="footer">
      <div className="content">
        <span className="links">
          <a href="/" className="secondary link">Marketing</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/about" className="secondary link">About</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/contact" className="secondary link">Contact</a>
        </span>
        <div className="rights">&copy; 2020 All Rights Reserved - American Business Consultants</div>
      </div>
      <div className="top-link">
        <a href="#top" className="primary button">back to top &nbsp;&#8679;</a>
      </div>
    </footer>
  )
}