import React from 'react'

import './styles.css'

export default function Marketplace() {
  return (
    <React.Fragment>

      {/* <!-- Section 1 --> */}
      <div id="marketplace" className="cntdiv slide">

        <div className="stagepromo">

          <h1>
            <span>Understanding the Marketplace </span>
            <br />
            How to Stand Out in the Crowd</h1>

          <h2>
              It&apos;s Not What You Market<br />
              <span>It&apos;s How You Market</span></h2>
          
          <br />

        </div>

        <div className="stage">

          <h2 style={{padding: '0 2%'}}>
            <span className="highlight" style={{fontSize: '120%'}}>What Matters Most</span> Is How to Attract People to Your Product or Service in A Crowded Marketplace</h2>

          <div className="container">

            <p>So, what is marketing anyway?</p>

            <p>American Business Consultants is all about marketing. Marketing is the backbone of a company. Regardless of the business you&apos;re in, you&apos;re really in the marketing business. </p>

            <h3>Let&apos;s define marketing: Marketing is what causes a company to actually function properly and make a profit rather than, BARELY MAKING ENDS MEET.</h3>

            <p>Most companies are confused about business terms such as: advertising, marketing, messaging, promotion, public relations and publicity, and sales, these are parts of marketing all to be overseen and managed by a marketing department. These terms are often used interchangeably. However, they refer to different, but similar actions. All these elements are often referred to as the marketing mix. Note: (Marketing is not part of sales, but sales is part of marketing).</p>

            <p>Advertising is a marketing tactic, and selling itself is, techniques of getting people to part with their cash for your product or service you are offering. Which is a tricky stratagem that requires understanding all the marketing parts and the process of consistent interconnected effort, to determine what will be effective, and then to create messaging arouse, and satisfy customer needs and wants.  It is also concerned with anticipating the customers&apos; needs and wants, which must be discovered through market research, which is influenced by social sciences, particularly psychology, sociology, and economics. Anthropology is also a small, but growing influence. Market research strengthens messaging principles.</p>

            <p>Messaging is most effective, when emphasis is focused on the unconscious inside (feelings, emotions etc.) – the core of a message should be developed with an inside approach, the psyche aspect of a group.</p>

            <p>Now days in the marketplace, things are so competitive, you may have a better product than all your competitors, but that does not mean everyone is going to buy from you rather than your competitor. In the service business doing the best job does not guarantee you customers. What matters most is fundamental tools of marketing, understanding how principles take control, creating a competitive edge and optimizing your business more effectively in the market.</p>

            <img src="/images/signs-caution.jpg" alt="caution sign" style={{
              width: '21%',
              minWidth: 180,
              float: 'left',
              marginRight: 18,
            }} />

            <p>Marketing has two threat issues; these two factors can impact the results of investigative findings; which will be difficult to counteract their influence, which obstruct the principles of marketing values. This is HUGE!</p>

            <p><strong>Self-deception</strong> is a process of denying or rationalizing away the relevance, significance, or importance of opposing evidence and logical argument. Self-deception involves convincing oneself of a truth, so that one does not reveal any self-knowledge of the deception.</p>

            <p><strong>Biased opinion</strong> favors one sided view of an issue over another. Biases will display a preference for one thing over another. In messaging the threat is a view that can be taken to the extreme. This corrupts the process of what research must do in the development of a message.</p>

            <p>Remember each target market has a different message! Each message you message out has its own set of value words that target a group, tailored for the group. A corrupted investigative research is just a waste of time, company time and money.</p>

            <h2><i>So, you want to understand marketing and how its principles fit.</i></h2>

            <p>Marketing is the root of doing business for profit and not loss. In nature you must plant a seed, nurture the seed, to have it pay off as a crop. Well, the effort of study, learning, and doing comes before the payoff of knowledge and understanding, this is how it all fits (a fractal pattern*). That is how everyone gains the basic understanding of marketing principles.</p>

            <p>Clear understanding is not developed by phenomena, it is and always will be by one’s efforts and work in learning. Don’t misunderstand; to blossom in marketing knowledge and bear the fruit of understanding is a process, and must be learned, you cannot disregard the process of learning, you must learn, and then do; not do, then learn (although one does learn when doing).</p>

            <p>Actual existing fact-evidence awaits you; you can have many A-hah! moments in the learning process; moments of sudden realization, inspiration, insight, and recognition, or comprehension, these are awaiting you.</p>

            <p><strong>If you feel you need evidence</strong> before you put forth effort? Then just start, and you will have your evidence, a stream of evidence. It is irrational to consider that one can do marketing and understand it without putting effort into learning; by study, doing, working on learning continually. There is no precedent that can tell you otherwise.</p>

            <p><i>* A fractal is a never-ending pattern. Fractals are infinitely complex patterns that are self-similar across different scales. They are created by repeating a simple process over and over in an ongoing feedback loop. Driven by recursion, fractals are images of dynamic systems.</i></p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}