import React from 'react'
import { Link } from 'react-router-dom'

import './styles.css'

export default function Header() {
  return (
    <header className="header">
      <h1 className="logo">
        <Link to="/">American Business Consultants</Link>
      </h1>
      <p className="links">
        <Link to="/">Marketing</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/about">About</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/contact">Contact</Link>
      </p>
    </header>
  )
}