import React from 'react'

import './styles.css'

export default function Promote() {
  return (
    <React.Fragment>

      {/* <!-- Section 5 --> */}
      <div id="promote" className="cntdiv slide light">

        <div className="stage">

          <h2>ANYONE CAN ADVERTISE, BUT TO PROMOTE, YOU MUST USE MARKETING PRINCIPLES</h2>

          <div className="container">

            <p>Things are so slippery nowadays in the marketplace.</p>

            <p>If you don&apos;t know where you are at in the marketplace because of the conditions of the marketplace, then how do you know where your company is headed? You will not begin to tell where you&apos;re headed unless you start an educational learning program, a course that teaches marketing laws and principles that give understanding.</p>

            <p>Ask yourself the following questions:</p>

            <ol>

              <li>Do you need increased sales and profits? Do you need fast cash-flow with a ton of new and repeat customers?</li>

              <li>Are you frustrated with advertising that doesn&apos;t work? Does your messaging seem to NOT draw attention. Are a lot of your dollars wasted because of bad results from your messaging? But you don&apos;t know what to do about it.</li>

              <li>If You&apos;re an expert at the operational side of your business, but not in marketing and messaging. Are you tired of getting bad advice from others?</li>

              <li>If your business is already successful. But you have a feeling in your heart, there must be hidden opportunities you&apos;re missing. Are you willing to to put in the time and effort to learn where the opportunities are hiding?</li>

            </ol>

            <p>Our Courses are an easy way to learn marketing principles and patterns of messaging. You can at your leisure use your phone, tablet, or computer, to take the courses when you have time. No matter where you are, 24/7. <strong>Marketing principles help you know where you&apos;re headed, and how to arrive at the correct decisions. This is much more likely when using laws of marketing.</strong></p>

          </div>

        </div>

      </div>

    </React.Fragment>
  )
}