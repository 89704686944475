import React from 'react'

import './styles.css'

export default function Keystone() {
  return (
    <React.Fragment>

      {/* <!-- Section 13 --> */}
      <div id="keystone" className="cntdiv slide light">

        <div className="stagepromo">

          <h1>Marketing IS A KEYSTONE For Business!</h1>

        </div>

        <div className="stage">

          <div className="container">

            <img src="/images/keystone.jpg" alt="keystone image" className="arch" />

            <p>Understand how the concepts of a keystone fit appropriately with marketing principles and patterns. When an arch such as this one is constructed correctly, it can remain standing even without any mortar between the stones; such is the application of marketing principles in all business and especially messaging in the marketplace. What holds the arch together is the keystone; it holds the other stones in place and prevents the arch from collapsing. Principles of marketing and its laws are KEYSTONES.</p>

            <p>Experiences confirm principles of true marketing.</p>            

            <p>The keystone of an arch holds the other stones in place, and &quot;just as the arch crumbles if the keystone is removed&quot;, so does all business stand or fall when appropriate marketing principles and patterns are applied and followed, or not. You cannot follow what you don&apos;t understand. You can learn these, it&apos;s doable, but you have to do it!</p>            

            <p style={{ clear: 'both' }}></p>
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}