import React from 'react'

import './styles.css'

export default function Principles() {
  return (
    <React.Fragment>

      {/* <!-- Section 6 --> */}
      <div id="principles" className="cntdiv slide light">

        <div className="stage">

          <div className="container">

            <img src="/images/city.jpg" alt="Laws &amp; Principles of Marketing"
              style={{
                borderRadius: '16px',
                maxWidth: '40%',
                float: 'right',
                margin: '15px 0 15px 40px',
              }} />

            <h1>
              Laws &amp; Principles of MARKETING</h1>

            <p><strong>All the advanced technology on the planet can&apos;t produce profits unless that technology is backed by a solid working knowledge and marketing principles. Understanding principles is what makes marketing successful. We have repeated this many times, that&apos;s because it is true and very important!</strong></p>

            <p>Principles are anchors of safety. They are like the steel anchors a mountaineer uses to conquer otherwise impossible cliffs. They will help you have confidence in new and unfamiliar circumstances. They will provide you protection in life&apos;s storms of adversity (marketing adjustable solutions) in messaging in the marketplace, which will work in this overcrowded, overloaded, and congested condition.</p>
            
            <p>Armed with the principles of marketing and a clear comprehension of the patterns and of what it takes to market and navigate these days, that feat can be accomplished, not by chance, but by adherence to sound principles of marketing.</p>

            <h1 className="consider">
              Consider This &nbsp;&#8681;
            </h1>

            <p>If you produce a first-rate product, or your business and service offered is blue-ribbon quality. It is still possible that all your efforts in the promotion of your business might exist in a vacuum, if you do not market your offerings with the skill of principled marketing laws, then your messaging in the marketplace will be weak.</p>

            <p>Bad marketing choices establish the fact of consequences. Consequences plainly prove the application of laws and principles of marketing were, or were not followed.</p>
            
          </div>

        </div>

      </div>

    </React.Fragment>
  )
}